













import api from "@/api/index"; //ABP API接口
import {Component, Model, Prop, Vue} from "vue-property-decorator";
import {AttachmentCreateOrUpdateDto, AttachmentHostType} from "@/api/appService";
import OSS from "ali-oss"
import Guid from 'guid'

@Component({name: 'SingleFileUploadOSS'})
export default class SingleFileUploadOSS extends Vue {

  @Model('change')
  file!: {
    url: '',
    name: '',
    id: 0
  };

  @Prop({required: false})
  hostId!: string;

  @Prop({required: false})
  hostType!: AttachmentHostType;

  btnText = '点击上传';
  fileUrl = '';
  imageUrl = '';

  fnUploadRequest(options: any) {
    let fileName = Guid.raw() + '.' + options.file.name.substring(options.file.name.lastIndexOf('.') + 1)
    let fileDirectory = 'oss/file/' + fileName;
    api.attachmentService.getAliyunOssStsToken().then(res => {
      this.fileUrl = res.fileUrl ?? '';
      this.imageUrl = res.imageUrl ?? '';

      let client = new OSS({
        region: res.region,
        accessKeyId: res.credentials?.accessKeyId ?? '',
        accessKeySecret: res.credentials?.accessKeySecret ?? '',
        stsToken: res.credentials?.securityToken,
        endpoint: res.endpoint,
        bucket: res.bucket,
        secure: true,
        cname: false
      });
      console.log("client",client)
      let _this = this as any;

      // 开始分片上传。
      async function multipartUpload() {
        try {
          debugger
          const result = await client.multipartUpload(fileDirectory, options.file, {
            progress: (p: any) => {
              // 上传进度
              // console.log(p); // Object的上传进度。
              options.onProgress({percent: p * 100, returnValue: true});
              _this.btnText = (p * 100).toFixed(0) + '%'
              if (p === 1) {
                _this.btnText = '点击上传'
              }
            }
          });
          _this.handleSuccess(result, options.file);
        } catch (e) {
          // 捕获超时异常。
          if (e.code === 'ConnectionTimeoutError') {
            console.log('TimeoutError');
            // do ConnectionTimeoutError operation
          }
          console.log(e);
        }
      }

      multipartUpload();
    })
  }

  private handleSuccess(res: any, file: any) {
    console.log(res)
    let attachment: AttachmentCreateOrUpdateDto = {
      id: 0,
      url: '',
      fileName: undefined,
      fileSize: undefined,
      contentLength: undefined,
      contentType: '',
      hostId: '',
      hostType: AttachmentHostType.Temp
    };
    // file.url = res.res.requestUrls[0].split("?")[0];
    let regex = new RegExp(/(\.(jpg|jpeg|png|bmp|gif))$/);
    let isImage = false;
    if (regex.test(res.name)) {
      isImage = true;
    }
    let url = '';
    if (isImage) {
      url = this.imageUrl;
    } else {
      url = this.fileUrl;
    }
    if (url.lastIndexOf('/') !== url.length - 1) {
      url += '/';
    }

    attachment.url = url + res.name;
    attachment.fileName = file.name;
    attachment.fileSize = file.size;
    attachment.contentLength = res.res.headers['content-length'];
    attachment.contentType = file.type;
    attachment.hostId = this.hostId;
    attachment.hostType = this.hostType;

    api.attachmentService.uploadAttachment({body: attachment}).then(res => {
      attachment.id = res.attachmentId;
    })

    console.log(attachment)
    this.$emit('change', attachment.url);
    this.$emit('upload-complete', attachment);
    //this.$emit('change', file);
  }

  private handleRemove() {
    // this.$emit('change', {});
    this.$emit('change', '');
  }
}
